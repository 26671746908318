import React, { Suspense } from 'react'
import { useRoutes } from 'react-router-dom';
import PublicRoute from './PublicRoute';

// imported Routes
const Landing = React.lazy(() => import('../views/Landing'));
const Space = React.lazy(() => import('../views/Space'));
const Galley = React.lazy(() => import('../views/gallery/'));
const Fitness = React.lazy(() => import('../views/gallery/Fitness'));
const Contact = React.lazy(() => import('../views/Contact'));
const RentelReq = React.lazy(() => import('../views/RentalReq'));

const loading = () => <div className=""></div>;

const LoadComponent = ({ component: Component }) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

function AllRoutes() {
  return useRoutes([
    {
      // public routes
      path: '/',
      element: <PublicRoute />,
      children: [
        {
          path: '',
          element: <LoadComponent component={Landing} />,
        },
        {
          path : 'space',
          element : <LoadComponent component={Space} />
        },
        {
          path : 'gallery',
          element : <LoadComponent component={Galley} />
        },
        {
          path : 'photos/:title',
          element : <LoadComponent component={Fitness} />
        },
        {
          path : 'rental',
          element : <LoadComponent component={RentelReq} />
        },
        {
          path : 'contact',
          element : <LoadComponent component={Contact} />
        },
      ],
    }
  ]);
}

export { AllRoutes }